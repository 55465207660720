<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle memberTitle="采购计划审批详情"></MemberTitle>
      <div class="container">
        <div style="padding: 0 20px 10px 20px; border: 1px solid #eeeeeeff">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #eeeeeeff;
              padding-bottom: 10px;
            "
          >
            <h3 style="margin-top: 10px; font-size: 16px">采购计划审批详情</h3>

            <div style="margin-top: 12px; display: flex">
              <el-button @click="goback">返回</el-button>
            </div>
          </div>
          <div class="sld_order_nav" style="display:flex">
            <div class="sld_order_nav_item addBorder distance">
              <p>
                <span class="fontColor">创建时间 :</span>
                {{ topTable.data.createTime }}
              </p>
              <p class="stateStyle">
                <span class="yellow" v-if="topTable.data.approveStatus == 0"
                  >待审批</span
                >
                <span class="bule" v-if="topTable.data.approveStatus == 1"
                  >审批通过</span
                >
                <span class="red" v-if="topTable.data.approveStatus == 2"
                  >审批拒绝</span
                >
              </p>
              <p><span>审批人：</span><span>{{topTable.data.approvalName}}</span></p>
            </div>
            <div class="sld_order_nav_item addBorder distance3">
              <div class="item_title">
                <h3>基本信息</h3>
                <div class="item_type">
                  <p v-if="topTable.data.type == 1">月度采购</p>

                  <p v-if="topTable.data.type ==0">单次采购</p>
                </div>
              </div>
              <div class="item_title_p">
              <p >
                <span class="fontColor">采购单号:</span
                ><span>{{ topTable.data.memberPlanCode }}</span>

                <img
                  v-if="topTable.data.source == 0"
                  src="@/assets/yongyou.png"
                />
                <img
                  v-if="topTable.data.source == 1"
                  src="@/assets/dashiLogo.png"
                />

                <img
                  v-if="topTable.data.source == 2"
                  src="@/assets/dashijicai@2x.png"
                />
              </p>
              <p>
                <span class="fontColor">创建人:</span
                ><span style="margin-right: 10px">
                  {{ topTable.data.prayName }} </span
                ><span>
                  {{ topTable.data.operator }}
                </span>
              </p>
              <p>
                <span class="fontColor">创建部门:</span
                ><span v-if="topTable.data.deptName">
                  {{ topTable.data.deptName }}
                </span>
                <span v-if="!topTable.data.deptName">
                  {{ topTable.data.corpName }}
                </span>
              </p>
              <p>
                <span class="fontColor">备注:</span
                ><span>
                  {{ topTable.data.desc }}
                </span>
              </p>
              </div>
            </div>
            <div class="sld_order_nav_item distance2">
              <div class="item_title">
                <h3>审批信息</h3>
              </div>

              <div class="ApprovalMessage">
                <div class="ApprovalMessageContent" v-if="topTable.approvalLogs.length !==0">
                  <el-timeline style="max-width: 600px">
                    <el-timeline-item
                      v-for="(activity, index) in topTable.approvalLogs"
                      :key="index"
                      color="#0E3177FF"
                    >
                      <div>
                        <div >
                          <span style="color:#999999FF">{{activity.title}}</span> : <span>{{activity.memberName}}</span><span style="margin-left:10px">{{activity.createTime}}</span>
                        </div>
                        <div v-if="(activity.reason)">
                          <span style="color:#999999FF">审批原因</span> : <span>{{activity.reason}}</span>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <el-empty description="暂无审批信息" v-else style="height:150px;:" :image-size='100'/>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            padding: 0 20px 15px 20px;
            border: 1px solid #eeeeeeff;
            margin-top: 20px;
            margin-bottom: 24px;
          "
        >
          <div class="goodsList">
            <h3 style="margin-bottom: 10px; margin-top: 10px; font-size: 16px">
              {{ "采购商品" }}
            </h3>
            <el-divider />
            <div id="scroll-container2" @scroll="handleScroll">
              <div class="waitList" >
                <div v-for="(item, index) in tableData.data" :key="index">
                  <div
                    class="finishList_item_title"
                   
                  >
                    <div class="lt" :style="item.goodsName.split('').length >35?'flex-direction: column;align-items: flex-start;line-height:1.5;max-width:50%;':'' ">
                  
                      <p
                        style="
                          margin-top: 2px;
                          font-size: 14px;
                          font-weight: bold;
                          margin-left: 10px;
                          max-width:100%;
                        "
                      >
                        {{ item.goodsName }}
                      </p>
                      <!--<p style="margin-left: 10px; padding-top: 3px">
                          {{ item.goodsName }}
                          </p>-->

                      <div style="display:flex">
                      <p
                        class="finishList_item_title_item"
                        v-if="item.goodsSpec"
                      >
                        <span class="fontColor">规格型号:</span>
                        <span style="max-width: 300px; display: inline-block"
                          >{{ item.goodsSpec }},{{ item.goodsMarque }}</span
                        >
                      </p>
                      <p
                        class="finishList_item_title_item"
                        v-if="!item.goodsSpec"
                      >
                        <span
                          class="fontColor"
                          style="max-width: 200px; display: inline-block"
                          >规格型号:</span
                        >
                        <span style="max-width: 300px; display: inline-block">{{
                          item.goodsMarque
                        }}</span>
                      </p>
                      <p class="finishList_item_title_item">
                        <span class="fontColor">计划采购:</span
                        ><span class="bule">
                          {{ item.purchaseNum }}{{ item.goodsUnit }}
                        </span>
                      </p>
                      <div class="finishList_item_title_item">
                        <p class="flex_row_center_center">
                          <img
                            :src="checkFiles"
                            @click="checkGoodsNum(item)"
                            style="width: 10px; height: 10px; margin-right: 5px; cursor: pointer;"
                            v-if="item.needUpdateLogs"
                          />
                          <span
                            class="fontColor"
                            style="max-width: 100px; display: inline-block"
                            >备注:</span
                          >
                          <span
                           
                            :title="item.desc"
                            style="max-width: 200px; display: inline-block"
                            v-if="item.desc.split('').length < 15"
                            >{{ item.desc?item.desc:"无" }}</span
                          >
                          <el-tooltip
                            v-else
                            class="box-item"
                            effect="dark"
                            :content="item.desc"
                            placement="top-start"
                          >
                            <span class="fontColor text-ellipsis">{{ item.desc }}</span>
                          </el-tooltip>
                        </p></div>
                      </div>
                      <!--<p class="finishList_item_title_item" v-if="platform==2">
                            <span class="fontColor">我的报价:</span
                            ><span class="bule" style="max-width:250px;display:inline-block">{{ item.mySupplierName }} </span>
                              <span span class="bule">￥{{
                                item.myPrice}}
                                </span>
                            
                          </p>-->
                    </div>
                    <div class="rt">
                      <div
                        style="display: flex; margin-right: 10px"
                        class="fontColor"
                        v-if="item.needUpdateLogs"
                      >
                        <p style="margin-right: 10px">
                          修改数量:<span style="color: #e99f27">{{item.needUpdateLogs.updateContent}}</span>
                        </p>
                        <p class="fontColor" style="display:flex;">
                          
                          <span>修改原因：</span>
                          <span  v-if="item.needUpdateLogs.reason.split('').length < 20">{{item.needUpdateLogs.reason?item.needUpdateLogs.reason:"无"}}</span>
                          <el-tooltip
                            v-else
                            class="box-item"
                            effect="dark"
                            :content=" item.needUpdateLogs.reason"
                            placement="top-start"
                          >
                            <span class="text-ellipsis">{{ item.stateName }}</span>
                          </el-tooltip>
                        </p>
                      </div>
                      
                      <el-button 
                        v-if="topTable.data.approveStatus==0"
                        size="mini"
                        @click="changeGoodsNum(item)"
                        style="display: absolute; right: 0px"
                        >修改采购数量</el-button
                       
                      >
                      <span class="fontColor" v-if="topTable.data.approveStatus != 0">{{item.statusName}}</span>
                    </div>

                  </div>
                  <!--<div
                        class="finishList_item_title2"
                        v-if="item.goodsName.split('').length > 20"
                      >
                      <div class="lt">
                        <p
                          style="
                            margin-top: 2px;
                            font-size: 14px;
                            font-weight: bold;
                            margin-left: 10px;
                          "
                        >
                          {{ item.goodsName }}
                        </p>
                       
                        <div style="display: flex">
                          <p
                            class="finishList_item_title_item"
                            v-if="item.goodsSpec"
                            
                          >
                            <span class="fontColor" style="width:55px; display:inline-block">规格型号:</span>
                            <span style="max-width:300px; display:inline-block">{{ item.goodsSpec }},{{ item.goodsMarque }}</span>
                          </p>
                          <p
                            class="finishList_item_title_item"
                            v-if="!item.goodsSpec"
                          >
                            <span class="fontColor" style="width:55px;display:inline-block">规格型号:</span>
                            <span  style="max-width:200px; display:inline-block">{{ item.goodsMarque }}</span>
                          </p>
                          <p class="finishList_item_title_item">
                            <span class="fontColor">采购数量:</span
                            ><span class="bule">
                              {{ item.purchaseNum }}{{ item.goodsUnit }}
                            </span>
                          </p>
                          <p class="finishList_item_title_item">
                            <span class="fontColor">需求日期:</span>
                            <span>{{ item.needDate }}</span>
                          </p>
                          <p class="finishList_item_title_item"> 
                          <div class="flex_row_center_center">
                              
                              <img :src="checkFiles" @click="checkGoodsNum(item.purchasePic)" style="width:10px;height:10px;">
                            
                            <span class="fontColor" style="max-width:100px; display:inline-block">备注:</span>
                            <span class="desc-clss" :title="item.desc" style="max-width:300px; display:inline-block">{{ item.desc }}</span></div>
                          </p>
                          <p class="finishList_item_title_item" v-if="platform==2">
                            <span class="fontColor">我的报价:</span
                            ><span class="bule"
                              >{{ item.mySupplierName }} ￥{{
                                item.myPrice
                              }}</span
                            >
                          </p>
                        </div>
                        </div>
                        <div class="rt">
                          <el-button size="mini" @click="handleCloseItem(item.needCode)" style="display: absolute;right: 0px;">关闭</el-button>
                        </div>
                      </div>-->
                </div>
              </div>
            </div>
            <el-divider border-style="dotted" />
            <div class="generateOrder">
              <div class="generateOrder_left">
                <span>
                  <span>
                    <span class="fontColor">采购计划总数:</span>
                    <span>{{  tableData.data.length }}</span
                    >件
                  </span>
                </span>
              </div>
              <div>
                <div class="generateOrder_right" v-if="topTable.data.approveStatus == 0">
                  <div class="generateOrder_button2" @click="approval('拒绝')" >审批拒绝</div>
                  <div class="generateOrder_button" @click="approval('通过')">审批通过</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看修改原因弹窗 -->
    <div class="checkCause">
      <el-dialog v-model="descImgVisible" title="查看修改原因" width="500px">
        <div class="desc-image__preview">
          <div class="desc-image__preview_content">
            <el-timeline style="max-width: 600px">
              <el-timeline-item
                v-for="(activity, index) in activities.data"
                :key="index"
                color="#0E3177FF"
              >
                <div>
                  <div style="margin-bottom: 18px;margin-top: 3px;">
                    <span class="operator" >{{activity.createBy}}</span>
                    <span style="font-size: 12px;margin-left: 10px">{{activity.createTime}}</span>
                  </div>
                  <div
                    style="
                      display: flex;
                      width: 90%;
                      justify-content: flex-start;
                    "
                  >
                    <p>
                      <span class="fontColor">修改数量:</span
                      ><span class="white">{{activity.updateContent}}</span>
                    </p>
                    <p class="fontColor" style="margin-left:40px">修改原因：{{activity.reason?activity.reason:"无"}}</p>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-dialog>
      <div class="changeNumModal">
        <el-dialog
          v-model="changeNumVisible"
          title="修改采购数量"
          width="400px"
        >
          <div class="changeNum">
            <p><span>计划采购</span>:<span class="white">{{itemObj.purchaseNum}}{{itemObj.goodsUnit}}</span></p>
            <p>
              <span>修改数量</span>:
              <el-input-number
                v-model="changeGooodsNumParms.updateNum"
                :min="1"
                size="small"
                @change="handleChange"
                style="margin-left: 10px; margin-right: 10px"
              />{{itemObj.goodsUnit}}
            </p>
            <p style="display: flex">
            <span style="color:red" v-if="changeGooodsNumParms.updateNum!=itemObj.purchaseNum">*</span>
              <span>修改原因</span>:<el-input
                v-model="changeGooodsNumParms.reason"
                style="width: 240px; margin-left: 10px"
                :rows="2"
                type="textarea"
                :maxlength="200"
                show-word-limit
                placeholder="请输入"
              />
            </p>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="()=>{
                changeNumVisible = false;
                changeGooodsNumParms.updateNum=itemObj.purchaseNum
                }">{{
                L["取 消"]
              }}</el-button>
              <el-button
                type="primary"
                @click="changeNum()"
                class="confirm"
                >{{ L["确 定"] }}</el-button
              >
            </span>
          </template>
        </el-dialog>
        
      </div>
      <el-dialog
          v-model="approvalVisible"
          :title="'审批'+type"
          width="400px"
        >
            <p style="display: flex">
              <span>审批原因</span>:<el-input
                v-model="reason"
                style="width: 260px; margin-left: 10px"
                :rows="3"
               
                type="textarea"
                placeholder="请输入"
              />
            </p>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="()=>{
                approvalVisible = false;
                reason='';
              }">{{
                L["取 消"]
              }}</el-button>
              <el-button
                type="primary"
                @click="clickApproval(type)"
                class="confirm"
                >{{ L["确 定"] }}</el-button
              >
            </span>
          </template>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import SldAddressOperation from "../../../components/PlanAddressOperation.vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import { platform } from "@/utils/config";
import checkFiles from "../../../assets/iconImages/checkFiles.png";
import download from "downloadjs";
export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
    SldAddressOperation,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const descImgVisible = ref(false);
    const multipleTableRef = ref();
    const dialogVisible = ref(false);
    const changeNumVisible = ref(false);
    const AllMoney = ref(0);
    const topTable = reactive({ data: [],approvalLogs:[] });
    const activeIndex = ref(0);
    const approvalVisible=ref(false);
    const type=ref("")
     const itemObj=ref({})
    const reason=ref("")
    const changeGooodsNumParms = ref({
      updateNum:"",
      reason: "",
      memberNeedCode:""
    });
    const activities = reactive({
      data: [
       
      ],
    });

    const pageData = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const multipleSelection = ref([]);
    const current_address_index = ref(0);
    
    //我的供应商输入

    //关闭弹窗
    const close = () => {
      addressVisibleFlag.value = false;
    };

    const debounce = (fn, time) => {
      let timer = null;
      return (...arges) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {}, time);
      };
    };

    //const pageNums = ref(0);
    const tableData = reactive({
      data: [],
      goodsList: [{ name: 1 }],
      needCount: {},
    });
    let param = reactive({
      memberId: store.state.memberInfo.memberId,
      planCode: "",
      source: "",
      prayName: "",
      startDate: "",
      endDate: "",
      resource: "",
      state: "",
      pageNum: 1,
      pageSize: 10,
    });

    //去商品详情
    const goGoodsDetail = (e) => {
      router.push({
        name: "GoodsDetail",
        query: {
          productId: e,
        },
      });
    };

    //返回
    const goback = () => {
      //router.push({
      //  path: "/member/procurement/procurementPlanApprovalList",
      //  query: {
      //    //planCode: code,
      //    //state: state,
      //    pageNum: route.query.pageNum,
      //    pageSize: route.query.pageSize,
      //  },
      //});
      window.location.href = 'about:blank'
		window.close()
    };

    //查看修改采购数量
    const checkGoodsNum = (item) => {
      descImgVisible.value = true;
       proxy

        .$get("api/shopp/member/plan/audit/history/need/logs", {
          memberNeedCode: item.memberNeedCode,
        })
        .then((res) => {
          if (res.code == 200) {
           activities.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };

    //const getDetailGoodsData = () => {
    //  proxy
    //    .$get("api/shopp/member/plan/audit/history/need/list", {
    //      memberPlanCode: route.query.memberPlanCode,
          
    //    })
    //    .then((res) => {
    //      if (res.code == 200) {
    //        tableData.data = res.data;
    //        tableData.data.forEach((item) => {
    //          item.isColor = false;
    //          item.borderColor = false;
    //        });
    //      }
    //    })
    //    .catch(() => {
    //      //异常处理
    //    });
    //};
    const handleFormValidate = async () => {
      if (!formRef.value) return false;
      return await formRef.value.validate().catch(() => false);
    };
    //获取详情数据
    const getDetailData = () => {
      console.log(route);
      proxy

        .$get("api/shopp/member/plan/audit/history/detail", {
          taskId: route.query.taskId,
        })
        .then((res) => {
          if (res.code == 200) {
            topTable.data = res.data;
            tableData.data=res.data.needs
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
      //proxy
      //  .$get("api/shopp/purchase/plan/needCount", {
      //    planCode: route.query.planCode,
      //  })
      //  .then((res) => {
      //    if (res.code == 200) {
      //      tableData.needCount = res.data;
      //    } else {
      //      ElMessage(res.msg);
      //    }
      //  })
      //  .catch(() => {
      //    //异常处理
      //  });
    };
    const getApprovalLogs=()=>{
      proxy

        .$get("api/shopp/member/plan/audit/history/approval/logs", {
          memberPlanCode: route.query.memberPlanCode,
        })
        .then((res) => {
          if (res.code == 200) {
            topTable.approvalLogs = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    }
    const changeGoodsNum = (item) => {
      console.log(item.memberNeedCode);
      itemObj.value=item
      changeGooodsNumParms.value.updateNum=Number(item.purchaseNum)
      changeGooodsNumParms.value.memberNeedCode=item.memberNeedCode
      console.log( changeGooodsNumParms);
      changeNumVisible.value = true;
    };
    const approval=(typeStr)=>{
      type.value=typeStr
      approvalVisible.value=true
    }
    const changeNum=()=>{
      console.log(changeGooodsNumParms.value.updateNum!=itemObj.value.purchaseNum,changeGooodsNumParms.value.updateNum,);
      if(changeGooodsNumParms.value.updateNum!=itemObj.value.purchaseNum){

      if(changeGooodsNumParms.value.reason!==""){
        proxy
        .$post("api/shopp/member/plan/audit/history/edit/purchase/num", changeGooodsNumParms.value,"json")
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success(res.msg);
            //getDetailGoodsData();
            getDetailData();
            changeNumVisible.value = false;
            changeGooodsNumParms.value.reason=""
          } else {
            ElMessage.error(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
      }else{
         ElMessage.warning("请填写修改原因");
      }
      }else{
         proxy
        .$post("api/shopp/member/plan/audit/history/edit/purchase/num", changeGooodsNumParms.value,"json")
        .then((res) => {
          if (res.code == 200) {
            ElMessage.success(res.msg);
            //getDetailGoodsData();
            getDetailData();
            changeNumVisible.value = false;
            changeGooodsNumParms.value.reason=""
          } else {
            ElMessage.error(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
      }
    }
    const clickApproval=(type)=>{
      let resultApproval=""
      if(type=="拒绝"){
        resultApproval=false
      }else{
        resultApproval=true
      }
       proxy
        .$post("api/shopp/member/plan/audit/history/approval", {
          taskId: route.query.taskId,
          resultApproval,
          reason:reason.value
        },"json")
        .then((res) => {
          if (res.code == 200) {
            ElMessage(res.msg);
             getDetailData();
            //getDetailGoodsData();
            
            approvalVisible.value = false;
            goback()
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    }
    onMounted(() => {
      //if (route.query.orderState) {
      //  current_state.value = route.query.orderState;
      //} else {
      //  current_state.value = "";
      //}
      //if (route.query.evaluateState) {
      //  evaluate_state.value = route.query.evaluateState;
      //} else {
      //  evaluate_state.value = "";
      //}

      getDetailData();
      //getDetailGoodsData();
      getApprovalLogs()
    });
    const tableHeaderColor = (data) => {
      console.log(data, 34343);
      return {
        background: "#F6F6F6FF",
        color: "#333333FF",
        fontWeight: "bold",
      };
    };
    return {
      L,
      param,
      tableData,
      multipleTableRef,
      multipleSelection,
      descImgVisible,
      AllMoney,
      checkGoodsNum,
      goback,
      close,
      type,
      tableHeaderColor,
      activeIndex,
      current_address_index,
      getDetailData,
      goGoodsDetail,
      dialogVisible,
      debounce,
      topTable,
      //getDetailGoodsData,
      route,
      router,
      platform,
      activities,
      checkFiles,
      changeGooodsNumParms,
      changeGoodsNum,
      changeNumVisible,
      type,
      approval,
      approvalVisible,
      itemObj,
      changeNum,
      reason,
      clickApproval
    };
  },
};
</script>
<style lang="scss" >
@import "../../../style/procurementPlanApprovalDetail.scss";

.el-timeline-item__node--normal {
  left: 1px;
  width: 8px;
  height: 8px;
}
</style>